import { css } from "@emotion/core"
import React from "react"

export function Container({ children, ...props }) {
  return (
    <div
      css={css`
        flex-grow: 1;
        margin: 0 auto;
        width: auto;
      `}
      {...props}
    >
      {children}
    </div>
  )
}

export function Section({ children, ...props }) {
  return (
    <section
      css={css`
        padding: 3rem 1.5rem;
        display: block;
      `}
      {...props}
    >
      {children}
    </section>
  )
}

export function Title({ children, as: Element, ...props }) {
  return (
    <Element
      css={css`
        font-weight: 600;
        line-height: 1.125;
      `}
      {...props}
    >
      {children}
    </Element>
  )
}
